<template>
  <CRow>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="info" style="font-size: 26px;" :header="visits" :text="visits > 1 ? 'Visits Today' : 'Visit Today'">
        <!-- <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <!-- <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[65, 59, 84, 84, 51, 55, 40]"
            point-hover-background-color="primary"
            label="Members"
            labels="months"
          />
        </template> -->
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="warning" style="font-size: 26px;" :header="onGoing" :text="onGoing > 1 ? 'Visits On Going' : 'Visit On Going'">
        <!-- <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
            :caret="false"
          >
            <template #toggler-content>
              <CIcon name="cil-location-pin"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <!-- <template #footer>
          <CChartLineSimple
            pointed
            class="mt-3 mx-3"
            style="height:70px"
            :data-points="[1, 18, 9, 17, 34, 22, 11]"
            point-hover-background-color="info"
            :options="{ elements: { line: { tension: 0.00001 }}}"
            label="Members"
            labels="months"
          />
        </template> -->
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="danger" style="font-size: 26px;" :header="pending" :text="pending > 1 ? 'Visits Requested' : 'Visit Requested'"
      >
       <!--  <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
              <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <!-- <template #footer>
          <CChartLineSimple
            class="mt-3"
            style="height:70px"
            background-color="rgba(255,255,255,.2)"
            :data-points="[78, 81, 80, 45, 34, 12, 40]"
            :options="{ elements: { line: { borderWidth: 2.5 }}}"
            point-hover-background-color="warning"
            label="Members"
            labels="months"
          />
        </template> -->
      </CWidgetDropdown>
    </CCol>
    <CCol sm="6" lg="3">
      <CWidgetDropdown color="dark" style="font-size: 26px;" :header="cancel" :text="cancel > 1 ? 'Visits Closed' : 'Visit Closed'"
      >
        <!-- <template #default>
          <CDropdown
            color="transparent p-0"
            placement="bottom-end"
          >
            <template #toggler-content>
             <CIcon name="cil-settings"/>
            </template>
            <CDropdownItem>Action</CDropdownItem>
            <CDropdownItem>Another action</CDropdownItem>
            <CDropdownItem>Something else here...</CDropdownItem>
            <CDropdownItem disabled>Disabled action</CDropdownItem>
          </CDropdown>
        </template> -->
        <!-- <template #footer>
          <CChartBarSimple
            class="mt-3 mx-3"
            style="height:70px"
            background-color="rgb(250, 152, 152)"
            label="Members"
            labels="months"
          />
        </template> -->
      </CWidgetDropdown>
    </CCol>
  </CRow>
</template>

<script>
import http from '@/utils/http-common';

import { CChartLineSimple, CChartBarSimple } from '../charts/index.js'

export default {
  name: 'WidgetsDropdown',
  components: { CChartLineSimple, CChartBarSimple },
  data () {
		return {
			visits: '0',
      pending: '0',
      cancel: '0',
      onGoing: '0'
    }
  },
  methods: {
    getVisits() {
			let self = this;
			/* return http.get('/visits')
			.then(function (response) {
				let list = response.data.data
				self.visits = response.data.data.length;
        self.pending = list.filter(function(item) {
    			return (item.status === "Pending");  
				}).length;
        self.cancel = list.filter(function(item) {
    			return (item.status === "Cancel");  
				}).length;
        self.ongoing = list.filter(function(item) {
    			return (item.status === "On-Going");  
				}).length;
				self.isLoading= false;
			}).catch(function (error) {
				console.log(error);
				self.isLoading= false;
			}); */
      return http.post('/visits/status')
			.then(function (response) {
				console.log(response.data.data)
        self.visits = response.data.data.today.toString();
				self.pending = response.data.data.pending.toString();
        self.cancel = response.data.data.close.toString();
        self.onGoing = response.data.data['on-going'].toString();
			}).catch(function (error) {
				console.log(error);
			});
		},
  },
  mounted () {
		this.getVisits();
	},
}
</script>
